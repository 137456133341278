html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: var(--theme-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.web3modal-modal-lightbox {
  z-index: 50 !important;
}