.page {
  height: 100vh;
  @apply m-0 p-0 relative text-white;
}

.page-home {
  background-color: var(--theme-color-2);
  color: #B0C0BF;
}

.page-wallet {
  background-color: var(--theme-color);
}

.section-content {
  min-height: 100vh;
  padding-bottom: var(--footer-height);
}

:root {
  --pet-logo-width: 240px;
}

footer.section-footer {
  height: var(--footer-height);
  margin-top: calc(var(--footer-height) * -1);
  background: url(../assets/imgs/footer-bg.png) center bottom / 100% auto no-repeat, var(--theme-color);

  .media-icon {
    @apply text-ptext hover:opacity-80 w-8 h-8 mr-2 flex items-center justify-center;
  }

  .col-title {
    background: linear-gradient(90deg, #AD1BAF 19.79%, #413D47 77.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .pet-logo {
    @apply absolute bottom-0;
    height: auto;
    width: var(--pet-logo-width);
    animation: walktrough 9.6s steps(240, end) infinite;
  }
}
 
@keyframes walktrough {
  0% {
    left: calc(var(--pet-logo-width) * -1);
  }
  100% {
    left: calc(100% + var(--pet-logo-width));
  }
}

[data-anchor] {
  scroll-margin-top: 240px;
}

header {
  @apply fixed inset-x-0 z-10;
  top: var(--header-topoffset);

  .header,
  .header .home-menu {
    background: rgba(37, 31, 65, 0.2);
    box-shadow: 0px 30px 30px rgba(41, 21, 85, 0.3);
    backdrop-filter: blur(30px);
  }

  .header {
    @apply mx-auto flex items-center justify-end;
    max-width: 1632px;
    height: var(--header-height);
    border-radius: 40px;
    padding: 0 3.676%;
    width: 85vw;

    .home-menu {
      @apply hidden;
      border-radius: 40px;
      width: 84px;
      height: 70px;
      transition: background 0.3s;

      &:hover {
        background: rgba(37, 31, 65, 0.4);
      }
    }

    .menu-line {
      width: 36px;
      height: 5px;
      @apply rounded-full border border-white transition-all duration-300 ease-in-out;
    }

    .menu-line + .menu-line {
      margin-top: 6px;
    }

    .menu-line.menu-line-3 {
      width: 26px;
    }

    &.active {

      .menu-line-1 {
        transform: rotate(45deg) translate(3px, 6px);
      }

      .menu-line-2 {
        transform: rotate(-45deg) translate(-2px, 0);
      }

      .menu-line.menu-line-2 {
        margin-top: 0;
      }

      .menu-line-3 {
        opacity: 0;
      }
    }

    .home-logo {
      position: absolute;
      width: 262px;
      height: 125px;
      left: 32px;
      bottom: 12px;
      background: url(../assets/imgs/header-logo.png) center / cover no-repeat at-2x;
    }

    nav {
      @apply font-rb2 text-3xs text-white 3xl:text-2md 2xl:text-2xs xl:text-lg lg:text-md md:text-2xs;

      a,
      button {
        @apply ml-8 3xl:ml-6 2xl:ml-4;
      }
    }
  }
}

.animate-nav-enter {
  opacity: 0;
  transform: translateX(calc(100% + 24px));
}

.animate-nav-enter-active {
  opacity: 1;
  transition: all 300ms;
  transform: translateX(0);
}

.animate-nav-exit {
  opacity: 1;
  transform: translateX(0);
}

.animate-nav-exit-active {
  opacity: 0;
  transition: all 300ms;
  transform: translateX(calc(100% + 24px));
}

.header-offset {
  padding-top: calc(var(--header-topoffset) + var(--header-height));
}

.home-banner {
  z-index: 1;
  width: 100vw;
  height: 58vw;
  background: url(../assets/imgs/banner.png) center/cover no-repeat;

  video {
    @apply absolute inset-0;
    opacity: 0;
  }
}

.home-section-intro {
  background: url(../assets/imgs/section-line.png) bottom center / 100% auto no-repeat;

  &:before,
  &:after {
    content: '';
    @apply absolute;
    width: 910px;
    height: 910px;
  }

  &:before {
    top: -352px;
    right: -455px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(35, 141, 118, 0.75) 0%, rgba(13, 44, 38, 0) 100%);
  }

  &:after {
    bottom: -12px;
    left: -455px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(35, 141, 118, 0.75) 0%, rgba(13, 44, 38, 0) 100%);
  }

  .content {
    max-width: 750px;
  }
}

.home-section-assets {
  background: url(../assets/imgs/section-line.png) top center / 100% auto no-repeat;
}

.section-plate {
  @apply absolute right-0;
  bottom: 0;
  transform: translateY(100%);
  width: 447px;
  background: url(../assets/imgs/intro.png) center / cover no-repeat;

  &:before {
    content: '';
    padding-top: 74.27%;
    display: block;
  }
}

@screen 3xl {

  header .header {
    width: 90vw;
    
    .home-logo {
      left: 40px;
      bottom: 20px;
      width: 210px;
      height: 100px;
    }
  }
}

@screen 2xl {

  .section-plate {
    transform: scale(0.6) translateY(100%);
    transform-origin: bottom right;
  }
}

@screen xl {

  :root {
    --footer-height: 560px;
  }

  header .header {
    width: 96vw;

    .home-logo {
      left: 16px;
      bottom: 20px;
      width: 131px;
      height: 62.5px;
    }
  }

  .section-plate {
    transform: scale(0.4) translateY(100%);
    transform-origin: bottom right;
  }
}

@screen md {

  :root {
    --header-topoffset: 34px;
    --header-height: 70px;
  }

  header .header {
    width: 100vw;
    @apply py-0 pl-0 pr-6;
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;

    .home-menu {
      @apply flex items-center justify-center cursor-pointer relative z-10;
    }

    .home-nav {
      background: rgba(37, 31, 65, 0.2);
      box-shadow: 0px 30px 30px rgba(41, 21, 85, 0.3);
      backdrop-filter: blur(30px);
      border-radius: 40px;
      @apply hidden absolute right-6 top-0 px-6 pb-6;
      padding-top: 70px;

      a,
      button {
        @apply block text-right ml-0 mt-6;
      }
    }

    &.active {

      .home-menu {
        background: transparent;
        box-shadow: none;
        backdrop-filter: none;
      }

      .home-nav {
        @apply block;
      }
    }
  }
  
  .page-wallet header .header {
    
    .home-menu,
    .home-nav {
      background-color: rgba(198, 31, 203, 0.05); 
      border: 1px solid rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(20px);
    }

    &.active {

      .home-menu {
        background: transparent;
        border: none;
        backdrop-filter: none;
      }
    }
  }

  .home-section-intro {

    &:before,
    &:after {
      content: none;
    }
  }

  .section-plate {
    transform: none;
    @apply relative bottom-auto mx-auto mt-8;
    width: 100%;
    max-width: 447px;
  }
}

@screen sm {

  :root {
    --footer-height: 0;
  }

  .section-content {
    min-height: 0;
    @apply pb-0;
  }

  footer {
    height: auto !important;
  }
}