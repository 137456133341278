.p-title {
  @apply font-rb2-b inline-block;
  
  background: linear-gradient(90deg, #FFFCFF 19.79%, #B0D566 77.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &.theme2 {
    background: linear-gradient(90deg, #FFFCFF 19.79%, #C61FCB 77.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.pet-card {
  background-color: rgba(198, 31, 203, 0.05); 
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
}

.icon-pet {
  width: 80px;
  height: 62px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 0;

  &-cat {
    background-image: url('../assets/imgs/icon-cat.png') at-2x;
  }

  &-dog {
    background-image: url('../assets/imgs/icon-dog.png') at-2x;
  }

  &-mouse {
    background-image: url('../assets/imgs/icon-mouse.png') at-2x;
  }
}

.qrcode-btn {
  background: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  box-shadow: 0px 30px 30px rgba(69, 42, 124, 0.15);
  backdrop-filter: blur(30px);
  border-radius: 14px;
}

.pet-carousel {
}

.pet-slide {
  @apply relative overflow-hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;

  &:before {
    content: '';
    @apply absolute top-0 left-0 right-0;
    padding-top: 100%;
    background: linear-gradient(200.44deg, #126422 13.57%, #FFD541 98.38%);
    box-shadow: 0px 10px 30px rgba(184, 51, 154, 0.3);
    border-radius: 20px;
    transform: matrix(1, 0.07, 0, 1, 0, 0);
  }

  .pet-container {
    @apply p-4;
    z-index: 1;
    background: rgba(40, 27, 90, 0.1);
    backdrop-filter: blur(20px);
  }
  

  .pet-thumb {
    @apply bg-center bg-no-repeat;
    background-size: 100% auto; 
    border-radius: 20px;
    
    &:after {
      content: '';
      @apply block;
      padding-top: 100%;
    }
  }
}

.stat-col {
  @apply text-center;

  --bsize: 360px;

  .stat-thumb {
    width: var(--bsize);
    height: var(--bsize);
    background-image: url('../assets/imgs/petbag-sprite.png');
    @apply bg-cover bg-no-repeat relative;

    &:before {
      content: "";
      @apply absolute inset-x-0 bottom-0 bg-no-repeat;
      height: 70%;
      background-size: 100% auto;
      background-position: top center;
    }
  }

  .stat-mouse {

    &:before {
      background-image: url('../assets/imgs/stat-mouse.png');
      transform: scale(0.7) translate(-11.11%, -16.67%) rotate(-30deg);
    }

    &.stat-lv1 {
      background-position: calc(var(--bsize) * -9) 0;
    }

    &.stat-lv2 {
      background-position: calc(var(--bsize) * -8) 0;
    }

    &.stat-lv3 {
      background-position: calc(var(--bsize) * -7) 0;
    }

    &.stat-lv4 {
      background-position: calc(var(--bsize) * -6) 0;
    }

    &.stat-lv5 {
      background-position: calc(var(--bsize) * -5) 0;
    }
  }

  .stat-cat {

    &:before {
      background-image: url('../assets/imgs/stat-cat.png');
      transform: scale(0.7) translate(11.11%, 0);
    }

    &.stat-lv1 {
      background-position: calc(var(--bsize) * -4) 0;
    }

    &.stat-lv2 {
      background-position: calc(var(--bsize) * -3) 0;
    }

    &.stat-lv3 {
      background-position: calc(var(--bsize) * -2) 0;
    }

    &.stat-lv4 {
      background-position: calc(var(--bsize) * -1) 0;
    }

    &.stat-lv5 {
      background-position: 0 0;
    }
  }

  .stat-dog {

    &:before {
      background-image: url('../assets/imgs/stat-dog.png');
      transform: scale(0.7) translate(0, -8.33%) rotate(-10deg);
    }

    &.stat-lv1 {
      background-position: calc(var(--bsize) * -14) 0;
    }

    &.stat-lv2 {
      background-position: calc(var(--bsize) * -13) 0;
    }

    &.stat-lv3 {
      background-position: calc(var(--bsize) * -12) 0;
    }

    &.stat-lv4 {
      background-position: calc(var(--bsize) * -11) 0;
    }

    &.stat-lv5 {
      background-position: calc(var(--bsize) * -10) 0;
    }
  }
}

@screen 2xl {
  
  .pet-carousel {

    .pet-slide:nth-child(5) {
      @apply hidden;
    }
  }

  .stat-col {
    --bsize: 300px;
  }
}

@screen xl {

  .stat-col {
    --bsize: 240px;
  }
}

@screen sm {

  .icon-pet {
    width: 40px;
    height: 31px;
  }
}